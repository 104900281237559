import React, { useEffect } from "react"
import Layout, { MyLocationEnum } from '../components/Layout'
import SEO, { MetaOG } from "../components/Seo"

export default ({ location }) => {
  
  const seo_og: MetaOG = {
    title: "Advania",
    description: "Bjórjól 2020",
  }

  return (
    <Layout location={location} myLocation={MyLocationEnum.Lobby}>
      <SEO
        title="Advania"
        description="Bjórjól 2020"
        og={seo_og}
      />
    </Layout>
  )
}